// pure JS appHeight - 100vh fix
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}
window.addEventListener('resize', appHeight);
appHeight();



// pure JS offset
/*function offset(el) {
  var rect = el.getBoundingClientRect(),
  scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
  scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}*/



// pure JS to collapse the navbar on scroll
/*window.onscroll = function() {
  let nav = document.querySelector('.navbar');
  let navFixedTop = document.querySelector('.fixed-top');

  if (nav === null || navFixedTop === null) return;

  let navOffset = offset(nav);
  //console.log(navOffset.left, navOffset.top);

  if (navOffset.top > 50) {
    navFixedTop.classList.add('top-nav-collapse');
  } else {
    navFixedTop.classList.remove('top-nav-collapse');
  }
};*/



// jQuery to collapse the navbar on scroll
/*$(window).scroll(function() {
  let nav = $('.navbar');
  let navFixedTop = $('.navbar-fixed-top');

  if (nav === null || navFixedTop === null) return;

  if (nav.offset().top > 50) {
    navFixedTop.addClass('top-nav-collapse');
  } else {
    navFixedTop.removeClass('top-nav-collapse');
  }
});*/



// jQuery to closes the Responsive Menu on Menu Item Click
/*$(function() {
  let navLink = $('.navbar a.navbar-brand, .navbar .navbar-collapse a.nav-link');
  let navToggler = $('.navbar-toggler:visible');

  navLink.bind('click', function() {
    if (!navToggler.hasClass('collapsed')) {
      navToggler.click();
    }
  });
});*/



// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function() {
  let $page = $('html, body');
  let $link = $('a.page-scroll')

  $link.bind('click', function() {
    let $anchor = $(this);

    $page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
      $page.stop();
    });

    $page.stop().animate({
      scrollTop: $($anchor.attr('href')).offset().top
    }, 1000, 'easeInOutExpo', function() {
      $page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
    });
    return false;
  });
});



// intro
$(function() {
  let $headerIntroViewport = $('.intro-viewport');
  let $headerIntroImage = $('.intro-image');
  let headerIntroImageWidth = $headerIntroImage.width();
  let headerIntroImageHeight = $headerIntroImage.height();
  let $headerIntroImageList = $('<ul class="intro-image__list"></ul>').appendTo($headerIntroImage);
  let $headerIntroImageListItem = [];
  let $headerIntroImageStyle = $('<style id="style-intro-background-image"></style>').appendTo($headerIntroImage);

  let headerIntroImageUrl = [
    'img/intro/image.jpg',
    //'img/intro/image-01.jpg',
    //'img/intro/image-02.jpg',
    //'img/intro/image-03.jpg',
    //'img/intro/image-04.jpg'
  ];

  //let headerIntroImageRandom = headerIntroImage[Math.floor(Math.random() * headerIntroImageUrl.length)];

  let headerIntroImageLeftIndex = 0;
  let headerIntroImageInited = false;

  let moveLeft = false;
  let moveRight = true;

  let $headerIntroImageSlidersList = $('<ul class="intro-image-sliders__list"></ul>').insertAfter($headerIntroImage);
  let $headerIntroImageSlidersListItem = $('<li class="intro-image-sliders__list-item intro-image-sliders__list-item--left"><i class="fa fa-angle-left"></i></li><li class="intro-image-sliders__list-item intro-image-sliders__list-item--right"><i class="fa fa-angle-right"></i></li>').appendTo($headerIntroImageSlidersList);
  let $headerIntroImageSlidersListItemLeft = $headerIntroImageSlidersList.find('.intro-image-sliders__list-item--left');
  let $headerIntroImageSlidersListItemRight = $headerIntroImageSlidersList.find('.intro-image-sliders__list-item--right');

  let $headerIntroImageDotsList = $('<ul class="intro-image-dots__list"></ul>').insertAfter($headerIntroImage);
  let $headerIntroImageDotsListItem = [];
  let headerIntroImageDotsInited = false;

  let autoMove = 5000; // 5s
  let autoMoveInterval;

  let actualViewportWidth = window.innerWidth;
  let desktopViewportWidth = 960;

  let actualViewport = ActualViewport();
  let lastViewport = actualViewport;

  let offset = $(window).scrollTop();

  let opacity;
  let opacityDefault = 0.25; // 0.5
  let opacityPercentageDefault = opacityDefault * 100;
  let opacityMultiplier = 1.5;

  //let blur;

  let scrollingPage = false;
  let scrollingPageTimeout = 1000; // 1s

  // intro items
  for (let i = 0; i < headerIntroImageUrl.length; i++) {
    $headerIntroImageListItem.push($('<li class="intro-image__list-item intro-image__list-item--' + i + '"></li>').appendTo($headerIntroImageList));
    $headerIntroImageStyle.append(
      '.intro-image__list-item--' + i + ' {' +
        'background-image: url("' + headerIntroImageUrl[i] + '");' +
      '}'
    );
  }

  StartCarousel();

  // control carousel
  function Control() {
    $headerIntroImageSlidersListItemLeft
      .off('click')
      .on('click', function() {
        AutoMoveReset();
        MoveLeft();
        AutoMove();
        return false;
      });
    $headerIntroImageSlidersListItemRight
      .off('click')
      .on('click', function() {
        AutoMoveReset();
        MoveRight();
        AutoMove();
        return false;
      });
  }

  // reorder items
  function Reorder() {
    for (let i = 0; i < $headerIntroImageListItem.length; i++) {
      let $item = jQuery($headerIntroImageListItem[i]);
      let itemWidth = headerIntroImageWidth;
      let itemPosition = (-headerIntroImageLeftIndex) * itemWidth;

      if (headerIntroImageInited) {
        $item.stop().animate({
          left: itemPosition
        }, 'slow', 'easeInOutExpo');
      } else {
        $item.css('left', itemPosition);
      }
    }

    headerIntroImageInited = true;

    // active item class
    jQuery($headerIntroImageListItem).each(function() {
      $(this).removeClass('intro-image__list-item--selected');
    });
    jQuery($headerIntroImageListItem[headerIntroImageLeftIndex]).addClass('intro-image__list-item--selected');
  }

  // moveLeft
  function MoveLeft() {
    if (headerIntroImageLeftIndex > 0) {
      headerIntroImageLeftIndex--;
      Reorder();
      Sliders();
      Dots();

      if (headerIntroImageLeftIndex == 0) {
        moveLeft = false;
        moveRight = true;
      }
    }
    return false;
  }

  // moveRight
  function MoveRight() {
    if (headerIntroImageLeftIndex < $headerIntroImageListItem.length - 1) {
      headerIntroImageLeftIndex++;
      Reorder();
      Sliders();
      Dots();

      if (headerIntroImageLeftIndex == $headerIntroImageListItem.length - 1) {
        moveRight = false;
        moveLeft = true;
      }
    }
    return false;
  }

  // sliders
  function Sliders() {
    if (headerIntroImageUrl.length > 1) {
      if (headerIntroImageLeftIndex > 0) {
        $headerIntroImageSlidersListItemLeft.attr('style', 'opacity: 1; pointer-events: auto;');
      } else {
        $headerIntroImageSlidersListItemLeft.attr('style', 'opacity: 0.25; pointer-events: none;');
      }

      if (headerIntroImageLeftIndex < $headerIntroImageListItem.length - 1) {
        $headerIntroImageSlidersListItemRight.attr('style', 'opacity: 1; pointer-events: auto;');
      } else {
        $headerIntroImageSlidersListItemRight.attr('style', 'opacity: 0.25; pointer-events: none;');
      }
    } else {
      $headerIntroImageSlidersList.hide();
    }
    return false;
  }

  // dots
  function Dots() {
    if (headerIntroImageUrl.length > 1) {
      // dots init
      if (!headerIntroImageDotsInited && $headerIntroImageDotsList.length != 0) {
        //$headerIntroImageDotsList.html(''); // reset

        for (let i = 0; i < $headerIntroImageListItem.length; i++) {
          $headerIntroImageDotsListItem.push($('<li class="intro-image-dots__list-item intro-image-dots__list-item--' + i + '"></li>').appendTo($headerIntroImageDotsList));
        }

        headerIntroImageDotsInited = true;
      }

      // active item class
      jQuery($headerIntroImageDotsListItem).each(function() {
        $(this).removeClass('intro-image-dots__list-item--selected');
      });
      jQuery($headerIntroImageDotsListItem[headerIntroImageLeftIndex]).addClass('intro-image-dots__list-item--selected');
    } else {
      $headerIntroImageDotsList.hide();
    }
  }

  // autoMove
  function AutoMove() {
    AutoMoveReset();

    if (autoMove != 0) {
      autoMoveInterval = window.setInterval(function() {
        if (offset <= headerIntroImageHeight && !scrollingPage) {
          if (moveLeft && headerIntroImageLeftIndex > 0) {
            MoveLeft();
          } else if (moveRight && headerIntroImageLeftIndex < $headerIntroImageListItem.length - 1) {
            MoveRight();
          }
          return false;
        }
        return false;
      }, autoMove);
    }
    return false;
  }

  // autoMove reset
  function AutoMoveReset() {
    window.clearInterval(autoMoveInterval);
  }

  // actual viewport
  function ActualViewport() {
    actualViewportWidth = window.innerWidth;

    if (actualViewportWidth < desktopViewportWidth) {
      // mobile viewport
      mobileViewportFlag = true;
      desktopViewportFlag = false;
      return "mobile";
    } else if (actualViewportWidth > desktopViewportWidth) {
      // desktop viewport
      return "desktop";
    }
  }

  // start carousel
  function StartCarousel() {
    Control();
    Reorder();
    Sliders();
    Dots();
    AutoMove();
  }

  // reset
  function ResetCarousel() {
    headerIntroImageInited = false;
    //headerIntroImageDotsInited = false;

    headerIntroImageLeftIndex = 0;

    moveLeft = false;
    moveRight = true;

    actualViewport = ActualViewport();
    lastViewport = actualViewport;

    StartCarousel();
  }

  $(window).resize(function() {
    headerIntroImageWidth = $headerIntroImage.width();
    headerIntroImageHeight = $headerIntroImage.height();
    actualViewport = ActualViewport();
    Reorder();

    if (actualViewport != lastViewport) {
      ResetCarousel();
    }
  });

  $(window).scroll(function() {
    offset = $(window).scrollTop();

    if (offset >= headerIntroImageHeight) {
      // visibility
      $headerIntroViewport.invisible();

      // opacity by alpha
      $headerIntroImageList.attr('style', 'opacity: 0;');
    } else {
      // visibility
      $headerIntroViewport.visible();

      // opacity by alpha
      opacity = Math.round((offset / headerIntroImageHeight * opacityDefault * opacityMultiplier) * 100) / 100;
      opacity = Math.round((1 - opacity - (1 - opacityDefault)) * 100) / 100;
      if (opacity < 0) {
        opacity = 0;
      } else if (opacity > 1) {
        opacity = 1;
      }
      $headerIntroImageList.attr('style', 'opacity: ' + opacity + ';');

      //$headerIntroImage.removeAttr('style');
    }

    // scrolling timer
    scrollingPage = true;
    clearTimeout($.data(this, 'scrollTimer'));
    $.data(this, 'scrollTimer', setTimeout(function() {
      scrollingPage = false;
    }, scrollingPageTimeout));
  }).scroll();
});



// warning layer
$(function() {
  let $warningLayer = $('.warning__layer');
  let $warningClose = $warningLayer.find('.warning__close');

  // context menu desktop
  $(document).on('contextmenu', '.context-menu-protected', function(event) {
    $warningLayer.css('display', 'flex');
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    return false;
  })

  // close
  $warningClose.bind('click', function() {
    $warningLayer.hide();
    return false;
  });
});



// visible(), invisible()
jQuery.fn.visible = function() {
  return this.css('visibility', 'visible');
};
jQuery.fn.invisible = function() {
  return this.css('visibility', 'hidden');
};
